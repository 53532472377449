/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactNode } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

import { HTMLValues } from '../../data/utils/intl.utils';

type CustomFormattedMessageProps = MessageDescriptor & {
  tagName?: React.ElementType<any>;
  values?: Record<string, ((chunks: ReactNode[]) => JSX.Element) | any>;
};

export function CustomFormattedMessage({
  values,
  ...props
}: CustomFormattedMessageProps) {
  return (
    <FormattedMessage
      {...props}
      values={{ name: '', ...HTMLValues, ...values }}
    />
  );
}
