import './block.scss';

import { CategoryItemData, DocumentItemData } from '../../data/data/types';
import { makeKeyDownHandler } from '../../data/utils/make-key-down-handler';

type BlockProps = {
  data: CategoryItemData | DocumentItemData;
  onClick?: (value: CategoryItemData | DocumentItemData) => void;
};

export function Block({ data, onClick }: BlockProps) {
  return (
    <div
      className="block"
      role="link"
      tabIndex={0}
      onClick={() => onClick?.(data)}
      onKeyDown={makeKeyDownHandler(() => onClick?.(data))}
    >
      <div className="block__image">
        <img src={data.thumbnail} alt={data.name} />
      </div>
      <div className="block__content">
        <h2>{data.name}</h2>
      </div>
    </div>
  );
}
