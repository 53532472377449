import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useKeycloakContext } from '../../data/utils/keycloak-context';
import { Button, ButtonIcon, ButtonType } from '../button/button';
import { LanguageMenu } from '../intl/language-menu/language-menu';

export function Header() {
  const { client } = useKeycloakContext();

  const location = useLocation();

  const navigate = useNavigate();

  const showBackButton = useMemo(() => location.pathname !== '/', [location]);

  const onHandleBack = useCallback(() => {
    navigate('/');
  }, [navigate]);

  const onHandleLogout = useCallback(() => {
    client?.logout();
  }, [client]);

  return (
    <div className="page-header">
      <div className="page-header__left">
        {showBackButton && (
          <Button
            type={ButtonType.ROUND}
            icon={ButtonIcon.BACK}
            onClick={onHandleBack}
          />
        )}
      </div>
      <div className="page-header__center">
        <a href="/" title="Rodenstock">
          <img src="/interface/img/logo.svg" alt="Rodenstock" />
        </a>
      </div>
      <div className="page-header__right">
        <LanguageMenu />
        <Button
          type={ButtonType.ROUND}
          icon={ButtonIcon.LOGOUT}
          onClick={onHandleLogout}
        />
      </div>
    </div>
  );
}
