import './pdf-viewer-modal.scss';

import {
  KeyboardEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import ReactDOM from 'react-dom';

import { DocumentItemData } from '../../../data/data/types';
import { makeKeyDownHandler } from '../../../data/utils/make-key-down-handler';
import { CustomFormattedMessage } from '../../intl/custom-formatted-message';
import { PdfViewer } from '../pdf-viewer/pdf-viewer';

type PdfViewerModalProps = {
  documentItem: DocumentItemData;
  onClose?: () => void;
};

export function PdfViewerModal({ documentItem, onClose }: PdfViewerModalProps) {
  const container = document.getElementById('modal-container');

  const [active, setActive] = useState<boolean | null>(null);

  useEffect(() => {
    if (active === null) {
      setTimeout(() => {
        setActive(true);
      }, 100);
    }
  });

  const onHandleClick = useCallback(
    (e: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>) => {
      const target = e.target as HTMLElement;

      if (
        target.tagName !== 'INPUT' ||
        (target.tagName === 'INPUT' && target.getAttribute('type') === 'text')
      ) {
        e.preventDefault();
        e.stopPropagation();
      }
    },
    []
  );

  const onHandleClose = useCallback(
    (e: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>) => {
      e.preventDefault();
      e.stopPropagation();

      onClose?.();
    },
    [onClose]
  );

  if (!container) return null;

  return ReactDOM.createPortal(
    <div
      className={`pdf-viewer-modal${active ? ' active' : ''}`}
      role="button"
      tabIndex={0}
      onClick={onHandleClick}
      onKeyDown={makeKeyDownHandler(onHandleClick)}
    >
      <div className="pdf-viewer-modal__header">
        <img src="/interface/img/logo.svg" alt="Rodenstock" />
        <button type="button" className="close" onClick={onHandleClose}>
          <CustomFormattedMessage id="common.close" />
        </button>
      </div>
      <div className="pdf-viewer-modal__content">
        <PdfViewer documentItem={documentItem} />
      </div>
    </div>,
    container
  );
}
