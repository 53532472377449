import { useMemo } from 'react';

import { useApiClient } from '../../GraphQL/api/client';
import { useGetCategoryItemsQuery } from '../../types/api/graphql-types';

export function useCategoryItems(language: string | null | undefined) {
  const client = useApiClient();

  const { data: categoryItemsData, loading: categoryItemsLoading } =
    useGetCategoryItemsQuery({
      client,
      variables: {
        language: language ?? '',
      },
      skip: !language,
    });

  const data = useMemo(
    () => categoryItemsData?.categories ?? [],
    [categoryItemsData?.categories]
  );

  return {
    data,
    loading: categoryItemsLoading,
  };
}
