import './interface/styling/main.scss';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { KeycloakContext } from './data/utils/keycloak-context';
import { Header } from './elements/header/header';
import { IntlLoader } from './intl-loader';
import { KeycloakLoader } from './keycloak-loader';
import { CategoryPage } from './pages/categories/category';
import { HomePage } from './pages/home/home';

export function App() {
  const language = localStorage.getItem('locale');

  return (
    <KeycloakLoader>
      <KeycloakContext.Consumer>
        {({ user }) => (
          <IntlLoader locale={language ?? user?.languageCode}>
            <BrowserRouter>
              <Header />
              <div className="page-content">
                <div className="page-content__content">
                  <Routes>
                    <Route path="" element={<HomePage />} />

                    <Route path="/categories">
                      <Route path=":id" element={<CategoryPage />} />
                    </Route>
                  </Routes>
                </div>
              </div>
            </BrowserRouter>

            <div id="modal-container" />
          </IntlLoader>
        )}
      </KeycloakContext.Consumer>
    </KeycloakLoader>
  );
}
