import './pdf-viewer.scss';

import { useEffect, useRef } from 'react';

import { useDocumentItem } from '../../../data/data/documents/use-document-item';
import { DocumentItemData } from '../../../data/data/types';

type PdfViewerProps = {
  documentItem: DocumentItemData;
};

export function PdfViewer({ documentItem }: PdfViewerProps) {
  const pdfVierRef = useRef(null);

  const { data: documentItemData } = useDocumentItem(documentItem.id);

  useEffect(() => {
    let flipbook: any = null;

    if (pdfVierRef.current && documentItemData) {
      flipbook = $(pdfVierRef.current).FlipBook({
        pdf: documentItemData,
        controlsProps: {
          downloadURL: documentItemData,
          scale: {
            default: 0.7,
            min: 0.7,
          },
        },
        template: {
          html: '/interface/3d-flip-book/template.html',
          styles: [
            '/3d-flip-book/css/black-book-view.css',
            '/interface/3d-flip-book/css/main.css',
          ],
          script: '/interface/3d-flip-book/js/scripts.js',
          sounds: {
            startFlip: '/3d-flip-book/sounds/start-flip.mp3',
            endFlip: '/3d-flip-book/sounds/end-flip.mp3',
          },
        },
      });
    }

    return () => {
      if (flipbook) {
        flipbook.dispose();
      }
    };
  }, [documentItemData]);

  return <div className="pdf-viewer" id="pdf-viewer" ref={pdfVierRef} />;
}
