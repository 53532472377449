/* eslint-disable */
import { gql } from '@apollo/client';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Maybe<T> = T | null;
export type InputMaybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  UUID: any;
};

export type ApiQuery = {
  readonly __typename?: 'ApiQuery';
  readonly categories: ReadonlyArray<Category>;
  readonly document: Maybe<Scalars['String']>;
  readonly documents: ReadonlyArray<Document>;
  readonly languages: ReadonlyArray<Language>;
  readonly page: Page;
  readonly sections: ReadonlyArray<Section>;
  readonly user: User;
};


export type ApiQueryCategoriesArgs = {
  languageCode: InputMaybe<Scalars['String']>;
};


export type ApiQueryDocumentArgs = {
  documentId: Scalars['UUID'];
  languageCode: InputMaybe<Scalars['String']>;
};


export type ApiQueryDocumentsArgs = {
  languageCode: InputMaybe<Scalars['String']>;
  sectionId: Scalars['UUID'];
};


export type ApiQueryLanguagesArgs = {
  languageCode: InputMaybe<Scalars['String']>;
};


export type ApiQueryPageArgs = {
  languageCode: InputMaybe<Scalars['String']>;
};


export type ApiQuerySectionsArgs = {
  categoryId: Scalars['UUID'];
  languageCode: InputMaybe<Scalars['String']>;
};


export type ApiQueryUserArgs = {
  languageCode: InputMaybe<Scalars['String']>;
};

export type Category = {
  readonly __typename?: 'Category';
  readonly id: Scalars['UUID'];
  readonly name: Scalars['String'];
  readonly thumbnail: Scalars['String'];
};

export type Document = {
  readonly __typename?: 'Document';
  readonly id: Scalars['UUID'];
  readonly name: Scalars['String'];
  readonly thumbnail: Scalars['String'];
};

export type Language = {
  readonly __typename?: 'Language';
  readonly isoCode: Scalars['String'];
  readonly name: Scalars['String'];
};

export type Page = {
  readonly __typename?: 'Page';
  readonly imageBlock3: Maybe<Scalars['String']>;
  readonly textBlock0: Scalars['String'];
  readonly textBlock1: Scalars['String'];
  readonly textBlock2: Scalars['String'];
  readonly textBlock3: Scalars['String'];
  readonly titleBlock0: Scalars['String'];
  readonly titleBlock1: Scalars['String'];
  readonly titleBlock2: Scalars['String'];
  readonly titleBlock3: Scalars['String'];
};

export type Section = {
  readonly __typename?: 'Section';
  readonly documents: ReadonlyArray<Document>;
  readonly id: Scalars['UUID'];
  readonly name: Scalars['String'];
  readonly thumbnail: Scalars['String'];
};

export type User = {
  readonly __typename?: 'User';
  readonly countryCode: Scalars['String'];
  readonly countryId: Scalars['UUID'];
  readonly customerNumber: Scalars['String'];
  readonly languageCode: Scalars['String'];
  readonly languageId: Scalars['UUID'];
  readonly shopName: Maybe<Scalars['String']>;
  readonly userName: Scalars['String'];
};

export type UserDataFragment = { readonly __typename?: 'User', readonly shopName: string | null, readonly languageCode: string };

export type LanguageDataFragment = { readonly __typename?: 'Language', readonly name: string, readonly code: string };

export type CategoryDataFragment = { readonly __typename?: 'Category', readonly id: any, readonly name: string, readonly thumbnail: string };

export type CategorySectionDataFragment = { readonly __typename?: 'Section', readonly id: any, readonly name: string, readonly thumbnail: string };

export type DocumentDataFragment = { readonly __typename?: 'Document', readonly id: any, readonly name: string, readonly thumbnail: string };

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserQuery = { readonly __typename?: 'ApiQuery', readonly user: { readonly __typename?: 'User', readonly shopName: string | null, readonly languageCode: string } };

export type GetLanguageItemsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLanguageItemsQuery = { readonly __typename?: 'ApiQuery', readonly languages: ReadonlyArray<{ readonly __typename?: 'Language', readonly name: string, readonly code: string }> };

export type GetCategoryItemsQueryVariables = Exact<{
  language: InputMaybe<Scalars['String']>;
}>;


export type GetCategoryItemsQuery = { readonly __typename?: 'ApiQuery', readonly categories: ReadonlyArray<{ readonly __typename?: 'Category', readonly id: any, readonly name: string, readonly thumbnail: string }> };

export type GetCategorySectionItemsQueryVariables = Exact<{
  language: InputMaybe<Scalars['String']>;
  categoryId: Scalars['UUID'];
}>;


export type GetCategorySectionItemsQuery = { readonly __typename?: 'ApiQuery', readonly sections: ReadonlyArray<{ readonly __typename?: 'Section', readonly id: any, readonly name: string, readonly thumbnail: string, readonly documents: ReadonlyArray<{ readonly __typename?: 'Document', readonly id: any, readonly name: string, readonly thumbnail: string }> }> };

export type GetDocumentItemQueryVariables = Exact<{
  documentId: Scalars['UUID'];
}>;


export type GetDocumentItemQuery = { readonly __typename?: 'ApiQuery', readonly document: string | null };

export type GetPageDataQueryVariables = Exact<{
  language: InputMaybe<Scalars['String']>;
}>;


export type GetPageDataQuery = { readonly __typename?: 'ApiQuery', readonly page: { readonly __typename?: 'Page', readonly textBlock0: string, readonly titleBlock0: string, readonly textBlock1: string, readonly titleBlock1: string, readonly textBlock2: string, readonly titleBlock2: string, readonly textBlock3: string, readonly titleBlock3: string, readonly imageBlock3: string | null } };

export const UserDataFragmentDoc = gql`
    fragment UserData on User {
  shopName
  languageCode
}
    `;
export const LanguageDataFragmentDoc = gql`
    fragment LanguageData on Language {
  name
  code: isoCode
}
    `;
export const CategoryDataFragmentDoc = gql`
    fragment CategoryData on Category {
  id
  name
  thumbnail
}
    `;
export const CategorySectionDataFragmentDoc = gql`
    fragment CategorySectionData on Section {
  id
  name
  thumbnail
}
    `;
export const DocumentDataFragmentDoc = gql`
    fragment DocumentData on Document {
  id
  name
  thumbnail
}
    `;
export const GetCurrentUserDocument = gql`
    query getCurrentUser {
  user {
    ...UserData
  }
}
    ${UserDataFragmentDoc}`;
export type GetCurrentUserComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>, 'query'>;

    export const GetCurrentUserComponent = (props: GetCurrentUserComponentProps) => (
      <ApolloReactComponents.Query<GetCurrentUserQuery, GetCurrentUserQueryVariables> query={GetCurrentUserDocument} {...props} />
    );
    

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
      }
export function useGetCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
        }
export type GetCurrentUserQueryHookResult = ReturnType<typeof useGetCurrentUserQuery>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<typeof useGetCurrentUserLazyQuery>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<GetCurrentUserQuery, GetCurrentUserQueryVariables>;
export const GetLanguageItemsDocument = gql`
    query GetLanguageItems {
  languages {
    ...LanguageData
  }
}
    ${LanguageDataFragmentDoc}`;
export type GetLanguageItemsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetLanguageItemsQuery, GetLanguageItemsQueryVariables>, 'query'>;

    export const GetLanguageItemsComponent = (props: GetLanguageItemsComponentProps) => (
      <ApolloReactComponents.Query<GetLanguageItemsQuery, GetLanguageItemsQueryVariables> query={GetLanguageItemsDocument} {...props} />
    );
    

/**
 * __useGetLanguageItemsQuery__
 *
 * To run a query within a React component, call `useGetLanguageItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLanguageItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLanguageItemsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLanguageItemsQuery(baseOptions?: Apollo.QueryHookOptions<GetLanguageItemsQuery, GetLanguageItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLanguageItemsQuery, GetLanguageItemsQueryVariables>(GetLanguageItemsDocument, options);
      }
export function useGetLanguageItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLanguageItemsQuery, GetLanguageItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLanguageItemsQuery, GetLanguageItemsQueryVariables>(GetLanguageItemsDocument, options);
        }
export type GetLanguageItemsQueryHookResult = ReturnType<typeof useGetLanguageItemsQuery>;
export type GetLanguageItemsLazyQueryHookResult = ReturnType<typeof useGetLanguageItemsLazyQuery>;
export type GetLanguageItemsQueryResult = Apollo.QueryResult<GetLanguageItemsQuery, GetLanguageItemsQueryVariables>;
export const GetCategoryItemsDocument = gql`
    query GetCategoryItems($language: String) {
  categories(languageCode: $language) {
    ...CategoryData
  }
}
    ${CategoryDataFragmentDoc}`;
export type GetCategoryItemsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCategoryItemsQuery, GetCategoryItemsQueryVariables>, 'query'>;

    export const GetCategoryItemsComponent = (props: GetCategoryItemsComponentProps) => (
      <ApolloReactComponents.Query<GetCategoryItemsQuery, GetCategoryItemsQueryVariables> query={GetCategoryItemsDocument} {...props} />
    );
    

/**
 * __useGetCategoryItemsQuery__
 *
 * To run a query within a React component, call `useGetCategoryItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoryItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoryItemsQuery({
 *   variables: {
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGetCategoryItemsQuery(baseOptions?: Apollo.QueryHookOptions<GetCategoryItemsQuery, GetCategoryItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCategoryItemsQuery, GetCategoryItemsQueryVariables>(GetCategoryItemsDocument, options);
      }
export function useGetCategoryItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoryItemsQuery, GetCategoryItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCategoryItemsQuery, GetCategoryItemsQueryVariables>(GetCategoryItemsDocument, options);
        }
export type GetCategoryItemsQueryHookResult = ReturnType<typeof useGetCategoryItemsQuery>;
export type GetCategoryItemsLazyQueryHookResult = ReturnType<typeof useGetCategoryItemsLazyQuery>;
export type GetCategoryItemsQueryResult = Apollo.QueryResult<GetCategoryItemsQuery, GetCategoryItemsQueryVariables>;
export const GetCategorySectionItemsDocument = gql`
    query GetCategorySectionItems($language: String, $categoryId: UUID!) {
  sections(languageCode: $language, categoryId: $categoryId) {
    ...CategorySectionData
    documents {
      ...DocumentData
    }
  }
}
    ${CategorySectionDataFragmentDoc}
${DocumentDataFragmentDoc}`;
export type GetCategorySectionItemsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCategorySectionItemsQuery, GetCategorySectionItemsQueryVariables>, 'query'> & ({ variables: GetCategorySectionItemsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetCategorySectionItemsComponent = (props: GetCategorySectionItemsComponentProps) => (
      <ApolloReactComponents.Query<GetCategorySectionItemsQuery, GetCategorySectionItemsQueryVariables> query={GetCategorySectionItemsDocument} {...props} />
    );
    

/**
 * __useGetCategorySectionItemsQuery__
 *
 * To run a query within a React component, call `useGetCategorySectionItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategorySectionItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategorySectionItemsQuery({
 *   variables: {
 *      language: // value for 'language'
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useGetCategorySectionItemsQuery(baseOptions: Apollo.QueryHookOptions<GetCategorySectionItemsQuery, GetCategorySectionItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCategorySectionItemsQuery, GetCategorySectionItemsQueryVariables>(GetCategorySectionItemsDocument, options);
      }
export function useGetCategorySectionItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategorySectionItemsQuery, GetCategorySectionItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCategorySectionItemsQuery, GetCategorySectionItemsQueryVariables>(GetCategorySectionItemsDocument, options);
        }
export type GetCategorySectionItemsQueryHookResult = ReturnType<typeof useGetCategorySectionItemsQuery>;
export type GetCategorySectionItemsLazyQueryHookResult = ReturnType<typeof useGetCategorySectionItemsLazyQuery>;
export type GetCategorySectionItemsQueryResult = Apollo.QueryResult<GetCategorySectionItemsQuery, GetCategorySectionItemsQueryVariables>;
export const GetDocumentItemDocument = gql`
    query GetDocumentItem($documentId: UUID!) {
  document(documentId: $documentId)
}
    `;
export type GetDocumentItemComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetDocumentItemQuery, GetDocumentItemQueryVariables>, 'query'> & ({ variables: GetDocumentItemQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetDocumentItemComponent = (props: GetDocumentItemComponentProps) => (
      <ApolloReactComponents.Query<GetDocumentItemQuery, GetDocumentItemQueryVariables> query={GetDocumentItemDocument} {...props} />
    );
    

/**
 * __useGetDocumentItemQuery__
 *
 * To run a query within a React component, call `useGetDocumentItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentItemQuery({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useGetDocumentItemQuery(baseOptions: Apollo.QueryHookOptions<GetDocumentItemQuery, GetDocumentItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocumentItemQuery, GetDocumentItemQueryVariables>(GetDocumentItemDocument, options);
      }
export function useGetDocumentItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocumentItemQuery, GetDocumentItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocumentItemQuery, GetDocumentItemQueryVariables>(GetDocumentItemDocument, options);
        }
export type GetDocumentItemQueryHookResult = ReturnType<typeof useGetDocumentItemQuery>;
export type GetDocumentItemLazyQueryHookResult = ReturnType<typeof useGetDocumentItemLazyQuery>;
export type GetDocumentItemQueryResult = Apollo.QueryResult<GetDocumentItemQuery, GetDocumentItemQueryVariables>;
export const GetPageDataDocument = gql`
    query GetPageData($language: String) {
  page(languageCode: $language) {
    textBlock0
    titleBlock0
    textBlock1
    titleBlock1
    textBlock2
    titleBlock2
    textBlock3
    titleBlock3
    imageBlock3
  }
}
    `;
export type GetPageDataComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetPageDataQuery, GetPageDataQueryVariables>, 'query'>;

    export const GetPageDataComponent = (props: GetPageDataComponentProps) => (
      <ApolloReactComponents.Query<GetPageDataQuery, GetPageDataQueryVariables> query={GetPageDataDocument} {...props} />
    );
    

/**
 * __useGetPageDataQuery__
 *
 * To run a query within a React component, call `useGetPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPageDataQuery({
 *   variables: {
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGetPageDataQuery(baseOptions?: Apollo.QueryHookOptions<GetPageDataQuery, GetPageDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPageDataQuery, GetPageDataQueryVariables>(GetPageDataDocument, options);
      }
export function useGetPageDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPageDataQuery, GetPageDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPageDataQuery, GetPageDataQueryVariables>(GetPageDataDocument, options);
        }
export type GetPageDataQueryHookResult = ReturnType<typeof useGetPageDataQuery>;
export type GetPageDataLazyQueryHookResult = ReturnType<typeof useGetPageDataLazyQuery>;
export type GetPageDataQueryResult = Apollo.QueryResult<GetPageDataQuery, GetPageDataQueryVariables>;