import Keycloak from 'keycloak-js';
import { createContext, useContext } from 'react';

import { UserData } from '../data/types';

function getIssuerUrl() {
  return `https://${process.env.REACT_APP_KEYCLOACK_ISSUER_PREFIX}.${process.env.REACT_APP_KEYCLOACK_ISSUER_URL}`;
}

export const keycloakClient = new Keycloak({
  url: getIssuerUrl(),
  realm: 'cnxt',
  clientId: process.env.REACT_APP_KEYCLOACK_CLIENT_ID ?? 'b2b-portal',
});

type KeycloakContextState = {
  client: Keycloak | null;
  authenticated: boolean;
  user: UserData | null;
};

export const KeycloakContextProps = {
  client: null,
  authenticated: false,
  user: null,
};

export const KeycloakContext =
  createContext<KeycloakContextState>(KeycloakContextProps);

export const useKeycloakContext = () => useContext(KeycloakContext);

export const keycloakClientInitialize = keycloakClient.init({
  onLoad: 'login-required',
});
