import './button.scss';

import { MouseEvent, PropsWithChildren, useCallback } from 'react';

import { Spinner } from '../spinner/spinner';

export enum ButtonType {
  DEFAULT = 'primary',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  ICON = 'icon',
  TRANSPARENT = 'transparent',
  LINK = 'link',
  LIGHT = 'light',
  ROUND = 'round',
}

export enum ButtonSize {
  DEFAULT = 'default',
  SMALL = 'small',
}

export enum ButtonIcon {
  NONE = 'none',
  LOADING = 'loading',
  CLOSE = 'close',
  LOGOUT = 'logout',
  BACK = 'back',
  CHEVRON = 'chevron',
}

type ButtonProps = PropsWithChildren<{
  useAsSubmit?: boolean;
  type?: ButtonType;
  size?: ButtonSize;
  icon?: ButtonIcon;
  inverted?: boolean;
  danger?: boolean;
  active?: boolean;
  disabled?: boolean;
  bagde?: string | number;
  onClick?: (e: MouseEvent<HTMLElement>) => void;
}>;

export function Button({
  useAsSubmit = false,
  type = ButtonType.DEFAULT,
  size = ButtonSize.DEFAULT,
  icon = ButtonIcon.NONE,
  inverted = false,
  danger = false,
  active = false,
  disabled = false,
  bagde,
  onClick,
  ...props
}: ButtonProps) {
  const onHandleClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();

      onClick?.(e);
    },
    [onClick]
  );

  return (
    <button
      className={`button ${type}${
        size !== ButtonSize.DEFAULT ? ` ${size}` : ''
      }${inverted ? ` inverted` : ''}${danger ? ` danger` : ``}${
        active ? ` active` : ``
      }`}
      type={useAsSubmit ? 'submit' : 'button'}
      onClick={onHandleClick}
      disabled={disabled}
    >
      {bagde && <span className="badge">{bagde}</span>}

      {icon === ButtonIcon.LOADING && <Spinner />}

      {icon !== ButtonIcon.NONE && icon !== ButtonIcon.LOADING && (
        <i className={`icon ${icon}`} />
      )}

      {props.children && <span>{props.children}</span>}
    </button>
  );
}
