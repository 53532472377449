import './section.scss';

import { PropsWithChildren } from 'react';

type SectionProps = PropsWithChildren<{
  className?: string;
}>;

export function Section({ className, children }: SectionProps) {
  return (
    <div className={`section${className ? ` section--${className}` : ``}`}>
      <div className="section__content">{children}</div>
    </div>
  );
}
